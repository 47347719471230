import React from 'react'
import { Button, IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { isEmpty } from 'lodash'

import Footer from '../../components/Footer'
import { CardInfoEvent } from '../../components/CardInfoEvent'
import { Icons } from '../../components/Icons'
import iconStar from '../../assets/icons/star.svg'
import iconStarOutline from '../../assets/icons/starOutline.svg'
import iconThumbUp from '../../assets/icons/thumbUp.svg'
import iconThumbUpOutline from '../../assets/icons/thumbUpOutline.svg'
import iconThumbDown from '../../assets/icons/thumbDown.svg'
import iconThumbDownOutline from '../../assets/icons/thumbDownOutline.svg'
import EventHeroContianer from './EventHeroContainer'

// Tabpanels para secciones
function TabPanel(props) {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`event-tabpanel-${index}`}
      aria-labelledby={`event-tab-${index}`}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function tabsProps(index) {
  return {
    id: `event-tab-${index}`,
    'aria-controls': `event-tabpanel-${index}`,
  }
}

export default function EventContainer(props) {
  const {
    event: {
      imagesSite,
      resume,
      sections,
      vodTrailer
    },
    eventPreferences,
    handleOnChangePreferences,
    isUnlocked,
    currentTab,
    handleChange,
    useImageCDN
  } = props

  return (
    <main className='appWrap fixedHero'>
      <EventHeroContianer
        vodTrailer={vodTrailer}
        imagesSite={imagesSite}
        useImageCDN={useImageCDN}
      />
      <div className='mainWidth homeRowsWrap'>
        <section className='rowXxl showInfoHeader'>
          <div className='serieData'>
            <div className='serieReaction'>
              <div className='containerReaction'>
                <img
                  src={!isEmpty(imagesSite.logo)
                    ? useImageCDN
                      ? imagesSite.logo.replace('https://api-megago.megamedia.cl', 'https://megago.cdn.mdstrm.com')
                      : imagesSite.logo
                    : 'https://images2-mega.cdn.mdstrm.com/ott/placeholders-imgs/placeholder-logo.png'
                  }
                  alt=''
                  className='serieLogo'
                />
                <div className='buttonGroup'>
                  <Button
                    variant='outlined'
                    startIcon={<Icons path={eventPreferences && eventPreferences.favorite ? iconStar : iconStarOutline} />}
                    onClick={() => handleOnChangePreferences({ favorite: eventPreferences && eventPreferences.favorite ? !eventPreferences.favorite : true })}
                  >
                    Favorito
                  </Button>
                  <IconButton
                    color='primary'
                    aria-label='me gusta'
                    onClick={() => handleOnChangePreferences({ thumb: eventPreferences && eventPreferences.thumb === 'UP' ? '' : 'UP' })}
                  >
                    <Icons path={eventPreferences && eventPreferences.thumb === 'UP' ? iconThumbUp : iconThumbUpOutline} />
                  </IconButton>
                  <IconButton
                    color='primary'
                    aria-label='no me gusta'
                    onClick={() => handleOnChangePreferences({ thumb: eventPreferences && eventPreferences.thumb === 'DOWN' ? '' : 'DOWN' })}
                  >
                    <Icons path={eventPreferences && eventPreferences.thumb === 'DOWN' ? iconThumbDown : iconThumbDownOutline} />
                  </IconButton>
                </div>
              </div>
            </div>
            <div className='serieDescription'>
              <p>{resume}</p>
            </div>
          </div>
          {!isUnlocked &&
            <Link to='/planes'>
              <Button variant='contained' className='bigButtonAction'>Comprar evento</Button>
            </Link>
          }
        </section>

        <section className='rowXxl'>
          {/* Sections Tab */}
          <Tabs value={currentTab} onChange={handleChange} aria-label="event tabs" className="navHorizontal">
            {sections.map((menu, index) => (
              <Tab label={menu.title} {...tabsProps(index)} key={index} />
            ))}
          </Tabs>
          {/* Sections Panels */}
          {sections.map((section, index) => (
            <TabPanel value={currentTab} index={index} key={index}>
              <br />
              <div className='grid grid-3-cols cardLandscape'>
                {section.content.map((vod) => (
                  <li key={vod._id}>
                    <CardInfoEvent
                      vod={vod}
                      isUnlocked={isUnlocked}
                      pay={vod.pay}
                      type={section.type}
                      useImageCDN={useImageCDN}
                    />
                  </li>
                ))}
              </div>
            </TabPanel>
          ))}
        </section>
      </div>
      <Footer />
    </main>
  )
}